var __jsx = React.createElement;
import * as React from 'react';
import { createGlobalStyle } from 'styled-components';
import { BREAKPOINTS, MAX_BREAKPOINTS } from './breakpoints';

/**
 * .u-h1
 * .u-h2
 * .u-h3
 * .u-h4
 * .u-h5
 * .u-h6
 * .u-subtitle1
 * .u-subtitle2
 * .u-body1
 * .u-body2
 * .u-button
 * .u-caption
 * .u-overline
 */
function generateFontStyles() {
  var fontTypes = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2', 'button', 'caption', 'overline'];
  return fontTypes.map(function (fontType) {
    return "\n\t\t.u-".concat(fontType, " {\n\t\t\tfont-family: var(--g-font-family-").concat(fontType, ");\n      font-size: var(--g-font-size-").concat(fontType, ");\n      line-height: var(--g-line-height-").concat(fontType, ");\n      font-weight: var(--g-font-weight-").concat(fontType, ");\n      letter-spacing: var(--g-letter-spacing-").concat(fontType, ");\n\t\t}");
  });
}

// TODO: generate breakpoint classes
function generateSpacingClasses() {
  // uncomment to enable more spacing classes
  var types = ['m', 'my',
  // 'mx',
  // 'mt',
  'mb'
  // 'ml',
  // 'mr',
  // 'p',
  // 'py',
  // 'px',
  // 'pt',
  // 'pb',
  // 'pl',
  // 'pr',
  ];

  var spacingClasses = Array.from({
    length: 19
  }, function (_, i) {
    return i;
  }).map(function (index) {
    return types.map(function (type) {
      var spacingType = type[0] === 'm' ? 'margin' : 'padding';
      return "\n\t\t\t\t.u-".concat(type, "-").concat(index, " {\n\t\t\t\t\t").concat(spacingType, ": var(--g-spacing-").concat(index, ");\n\t\t\t\t}");
    });
  });
  return spacingClasses;
}
var Styles = createGlobalStyle(["", " .u-m-0{margin:var(--g-spacing-0);}@media (max-width:", "){.max-md\\:u-mb-3{margin-bottom:var(--g-spacing-3);}}.u-visually-hidden{border:0 !important;clip:rect(0 0 0 0) !important;clip-path:inset(50%) !important;height:1px !important;margin:-1px !important;overflow:hidden !important;padding:0 !important;position:absolute !important;white-space:nowrap !important;width:1px !important;}.u-full-width{width:100vw;position:relative;left:50%;right:50%;margin-left:-50vw;margin-right:-50vw;}"], generateFontStyles(), MAX_BREAKPOINTS.md);
var UtilityClasses = function UtilityClasses() {
  return __jsx(Styles, null);
};
export default UtilityClasses;